import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    // websocket实例
    websocket: null,
    // 手势动作监测信息
    gesture: null,
    // 当前播放音频
    currentAudio: null,
    // video实例
    videoInstance: null,
    // canvas实例
    canvasInstance: null,
    // canvas绘图实例
    canvasDrawInstance: null,
    // 截帧尺寸
    screenshotFpsSize: {
      width: 480,
      height: 290,
    },
  },

  getters: {},

  mutations: {
    CHANGE_WEBSOCKET(state, data) {
      state.websocket = data;
    },

    CHANGE_GESTURE(state, data) {
      state.gesture = data;
    },

    CHANGE_VIDEOINSTANCE(state, data) {
      state.videoInstance = data;
    },

    CHANGE_CANVASINSTANCE(state, data) {
      state.canvasInstance = data;
    },

    CHANGE_CANVASDRAWINSTANCE(state, data) {
      state.canvasDrawInstance = data;
    },
  },

  actions: {
    // 开启手势通道
    openGesturePassage() {
      const time = new Date().getTime();
      const socket = new WebSocket("wss://greeting-cv-gpt.ratuai.com/ws/" + time);
      // const socket = new WebSocket("ws://10.100.10.31:8001/ws/" + time);

      socket.addEventListener("open", () => {
        console.log("WebSocket连接已建立");
      });

      socket.addEventListener("message", event => {
        this.commit("CHANGE_WEBSOCKET", socket);

        const data = JSON.parse(event.data);
        this.commit("CHANGE_GESTURE", data.data);
        // console.log("收到消息：", JSON.stringify(data.data));
      });

      socket.addEventListener("close", () => {
        console.log("WebSocket连接已断开");
      });

      socket.addEventListener("error", error => {
        console.error("发生错误：", error);
      });
    },

    // 手势发送
    gestureSend({ commit, state }, data) {
      if (state.websocket) {
        this.state.websocket.send(JSON.stringify(data));
      }
    },

    // 播放音频
    startAudio({ commit, state, dispatch }, url) {
      dispatch("clearCurrentAudio");

      state.currentAudio = document.createElement("audio");
      document.body.appendChild(state.currentAudio);
      state.currentAudio.src = url;
      state.currentAudio.autoplay = true;
    },

    // 清除当前音频
    clearCurrentAudio({ commit, state, dispatch }) {
      // 判断是否存在播放音频，存在时清除播放音频
      if (state.currentAudio) {
        document.body.removeChild(state.currentAudio);
        state.currentAudio = null;
      }
    },

    // 开启截帧
    openScreenshot({ commit, state, dispatch }) {
      if (!state.canvasInstance) {
        console.log("创建canvas");
        const canvas = document.createElement("canvas");
        canvas.width = state.screenshotFpsSize.width;
        canvas.height = state.screenshotFpsSize.height;

        const imgCanvas = canvas.getContext("2d");
        const video = document.createElement("video");

        if (navigator.mediaDevices === undefined) {
          navigator.mediaDevices = {};
        }

        if (navigator.mediaDevices.getUserMedia === undefined) {
          navigator.mediaDevices.getUserMedia = function (constraints) {
            var getUserMedia = navigator.webkitGetUserMedia || navigator.mozGetUserMedia || navigator.getUserMedia;

            if (!getUserMedia) {
              return Promise.reject(new Error("getUserMedia is not implemented in this browser"));
            }

            return new Promise(function (resolve, reject) {
              getUserMedia.call(navigator, constraints, resolve, reject);
            });
          };
        }

        const constraints = {
          audio: false,
          video: {
            width: state.screenshotFpsSize.width,
            height: state.screenshotFpsSize.height,
            transform: "scaleX(-1)",
          },
        };

        navigator.mediaDevices.getUserMedia(constraints).then(function (stream) {
          if ("srcObject" in video) {
            video.srcObject = stream;
          } else {
            // 避免在新的浏览器中使用它，因为它正在被弃用。
            video.src = window.URL.createObjectURL(stream);
          }

          video.onloadedmetadata = function (e) {
            video.play();
          };

          commit("CHANGE_CANVASINSTANCE", canvas);
          commit("CHANGE_VIDEOINSTANCE", video);
          commit("CHANGE_CANVASDRAWINSTANCE", imgCanvas);
        });
      }
    },

    // 截帧
    screenshot({ commit, state, dispatch }) {
      return new Promise((resolve, reject) => {
        if (state.canvasInstance) {
          state.canvasDrawInstance.drawImage(
            state.videoInstance,
            0,
            0,
            state.screenshotFpsSize.width,
            state.screenshotFpsSize.height
          );
          const imageBase64Str = state.canvasInstance.toDataURL("image/png");
          resolve(imageBase64Str);
        }
      });
    },
  },

  modules: {},
});
