<template>
  <!-- 防止文本选中 -->
  <div id="demo_web_test" onselectstart="return false">
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  name: "App",

  created() {
    // 开启手势、人脸识别通道
    this.$store.dispatch("openGesturePassage");
  },
};
</script>

<style></style>
